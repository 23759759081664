import { useState, useEffect } from "react";

let webpSupportCache = null;

export function useWebPSupport() {
  const [isWebPSupported, setIsWebPSupported] = useState(webpSupportCache);

  useEffect(() => {
    if (webpSupportCache !== null) {
      return;
    }

    if (window.requestIdleCallback) {
      window.requestIdleCallback(() => {
        const checkWebPSupport = () => {
          try {
            const elem = document.createElement("canvas");
            if (elem.getContext && elem.getContext("2d")) {
              return elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
            }
            return false;
          } catch (e) {
            return false;
          }
        };

        webpSupportCache = checkWebPSupport();
        setIsWebPSupported(webpSupportCache);
      });
    } else {
      setTimeout(() => {
        const checkWebPSupport = () => {
          try {
            const elem = document.createElement("canvas");
            if (elem.getContext && elem.getContext("2d")) {
              return elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
            }
            return false;
          } catch (e) {
            return false;
          }
        };

        webpSupportCache = checkWebPSupport();
        setIsWebPSupported(webpSupportCache);
      }, 0);
    }
  }, []);

  return isWebPSupported;
}
