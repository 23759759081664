import React, { useState, useEffect, memo, useMemo } from "react";
import { withI18n } from "@lingui/react";
import { Helmet } from "react-helmet";
import { t } from "@lingui/macro";
import svgLogo from "../Header/assets/WeGoTripLogo.svg";
import { useWebPSupport } from "../../hooks/useWebPSupport";
import { BackgroundImage } from "./BackgroundImage";
import { Header } from "./Header";
import "./TopBar.css";

const backgroundDefaultImageWebp =
  "https://wegotrip-prod-storage.s3.eu-west-3.amazonaws.com/media/store/background-default.e7f414c0.webp";
const backgroundDefaultImageJpg =
  "https://wegotrip-prod-storage.s3.eu-west-3.amazonaws.com/media/store/background-default.e7f414c0.jpg";

/**
 * Block with search and advertising abilities
 * @param {Object} props
 * @param {String?} props.background - image got via `import` directive, will be default if not given
 */
const TopBar = memo(function TopBar({ background, i18n }) {
  const [showImage, setShowImage] = useState(false);
  const isWebPSupported = useWebPSupport();

  const imageSrc = useMemo(
    () => background || (isWebPSupported ? backgroundDefaultImageWebp : backgroundDefaultImageJpg),
    [background, isWebPSupported],
  );

  useEffect(() => {
    const preloadImage = () => {
      const img = new Image();
      img.src = imageSrc;
      img.onload = () => {
        setShowImage(true);
      };
    };

    if (window.requestIdleCallback) {
      window.requestIdleCallback(preloadImage);
    } else {
      setTimeout(preloadImage, 0);
    }

    return () => {
      setShowImage(false);
    };
  }, [imageSrc]);

  const metaTags = useMemo(
    () => ({
      title: i18n._(t`Self-guided audio tours on the mobile app`),
      description: i18n._(
        t`Book self-guided audio tours with skip-the-line tickets to museums, galleries, and many other attractions.`,
      ),
      image: svgLogo,
      siteName: "WeGoTrip",
      type: "website",
    }),
    [i18n],
  );

  return (
    <div className="TopBar">
      <Helmet>
        <link
          rel="preload"
          as="image"
          href={imageSrc}
          type={isWebPSupported ? "image/webp" : "image/jpeg"}
        />
        <meta property="og:title" content={metaTags.title} />
        <meta property="og:description" content={metaTags.description} />
        <meta property="og:image" content={metaTags.image} />
        <meta property="og:site_name" content={metaTags.siteName} />
        <meta property="og:type" content={metaTags.type} />
      </Helmet>
      <div className="TopBar__functional">
        {showImage && <BackgroundImage src={imageSrc} onLoad={() => setShowImage(true)} />}
        <Header />
      </div>
    </div>
  );
});

export default withI18n()(TopBar);
