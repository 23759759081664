/* eslint-disable react/no-unknown-property */
import React, { memo } from "react";
import { TOP_BAR_COVER_WIDTH } from "../../constants/image/sizes";
import getIndependentScreenSize from "../../functions/screen/getIndependentScreenSize";
import getRealSize from "../../functions/screen/getRealSize";
import backgroundDefaultImageJpg from "./assets/background-default.jpg";

let cachedWidth = null;

const getImageWidth = () => {
  if (cachedWidth === null) {
    cachedWidth = getRealSize(getIndependentScreenSize()) || TOP_BAR_COVER_WIDTH;
  }
  return cachedWidth;
};

export const BackgroundImage = memo(function BackgroundImage({ src, onLoad }) {
  const imageWidth = getImageWidth();

  return (
    <picture>
      <source type="image/webp" srcSet={src} sizes={`(max-width: 768px) 100vw, ${imageWidth}px`} />
      <img
        className="TopBar__background"
        width={imageWidth}
        height={350}
        src={backgroundDefaultImageJpg}
        alt="What will you discover next?"
        loading="eager"
        decoding="async"
        fetchpriority="high"
        onLoad={onLoad}
      />
    </picture>
  );
});
