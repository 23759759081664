import { useState, useEffect } from "react";

const breakpoints = {
  mobile: "(max-width: 767px)",
  tablet: "(min-width: 768px) and (max-width: 991px)",
  desktop: "(min-width: 992px)",
};

export const useMediaQueries = () => {
  const [mediaQueries, setMediaQueries] = useState({
    isMobile: false,
    isTablet: false,
    isDesktop: false,
  });

  useEffect(() => {
    const mediaQueryLists = {};
    const handlers = {};

    Object.entries(breakpoints).forEach(([key, query]) => {
      mediaQueryLists[key] = window.matchMedia(query);
      handlers[key] = e => {
        setMediaQueries(prev => ({
          ...prev,
          [`is${key.charAt(0).toUpperCase()}${key.slice(1)}`]: e.matches,
        }));
      };

      mediaQueryLists[key].addListener(handlers[key]);
      handlers[key](mediaQueryLists[key]);
    });

    return () => {
      Object.entries(mediaQueryLists).forEach(([key, mql]) => {
        mql.removeListener(handlers[key]);
      });
    };
  }, []);

  return mediaQueries;
};
