import React, { memo } from "react";
import { Trans } from "@lingui/macro";
import Search from "../Search";

export const Header = memo(function Header() {
  return (
    <div className="TopBar__header">
      <div className="Wrapper">
        <div className="TopBar__title">
          <Trans>What will you discover next?</Trans>
        </div>
        <Search className="TopBar__search" theme="big" isMainPage={true} />
      </div>
    </div>
  );
});
