import React, { useCallback, useState } from "react";
import get from "lodash/get";
import { Trans, t } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import { connect } from "react-redux";
import LazyHydrate from "react-lazy-hydration";
import loadable from "@loadable/component";
import { JsonLd } from "react-schemaorg";
import { useMediaQueries } from "../../hooks/useMediaQueries";
import * as types from "../../stores/types";
import inclineIfNeeded from "../../functions/inclineIfNeeded";
import { isMobileUserAgent, getUserAgent } from "../../functions/userAgent";
import { Api } from "../../functions/fetchFromApi";
import toQueryString from "../../functions/toQueryString";
import { getViewed } from "../../functions/viewedTours";
import { getProductLink } from "../../functions/getProductLink";
import { fetchOverview } from "../../functions/fetchData";
import removeNullKeys from "../../functions/removeNullKeys";
import reverseUrl from "../../functions/reverseUrl";
import { sendListViewedEvent } from "../../functions/analytics";
import { fetchDefaultCurrency } from "../../functions/currency";
import getCurrentLanguage from "../../functions/languages/getCurrentLanguage";
import withRedirectToKnownLang from "../../functions/languages/withRedirectToKnownLang";
import ScrollHook from "../../components/ScrollHook";
import Link from "../../components/Link";
import Button from "../../components/Button";
import TopBar from "../../components/TopBar";
import Root from "../../components/_Root";
import ErrorBoundary from "../../components/ErrorBoundary";
import { CanonicalAuto } from "../../components/Canonical";
import { AlternateAuto } from "../../components/Alternate";
import { DEFAULT_LANG } from "../../constants";
import { usePageViewTracking } from "../../functions/usePageViewTracking";
import withArrows from "../../components/ProductsSlider/withArrows";
import "./Main.css";

// Компоненты первого экрана с высоким приоритетом
const ProductCard = loadable(() => import("../../components/ProductCard"), {
  ssr: true,
  fallback: <div style={{ height: "200px" }} />,
  priority: true,
});

const Benefits = loadable(() => import("../../components/Benefits"), {
  ssr: true,
  fallback: <div style={{ height: "200px" }} />,
  priority: true,
});

// Компоненты второго экрана
const ProductSlider = loadable(() => import("../../components/ProductsSlider"), {
  ssr: true,
  fallback: <div style={{ height: "200px" }} />,
  priority: false,
});

const Attractions = loadable(() => import("../../components/Attractions"), {
  ssr: true,
  fallback: <div style={{ height: "200px" }} />,
  priority: false,
});

// Компоненты третьего экрана
const CitiesGroup = loadable(() => import("../../components/Cities"), {
  ssr: true,
  fallback: <div style={{ height: "200px" }} />,
  priority: false,
});

const ProductTabs = loadable(() => import("../../components/ProductsTabs"), {
  ssr: true,
  fallback: <div style={{ height: "200px" }} />,
  priority: false,
});

const ProductsGrid = loadable(() => import("../../components/ProductsGrid"), {
  ssr: true,
  fallback: <div style={{ height: "200px" }} />,
  priority: false,
});

// Компоненты нижней части страницы
const Banner = loadable(() => import("../../components/Banner"), {
  ssr: true,
  fallback: <div style={{ height: "200px" }} />,
  priority: false,
});

/**
 * Modifying components
 */
const ProductCarousel = withArrows(ProductSlider);

function Viewed({ lang = DEFAULT_LANG, products = [] }) {
  const { isDesktop } = useMediaQueries();
  const slidesPerPage = isDesktop ? 4 : 3;
  const productListId = "viewed";

  /**
   * Product list became visible in viewport
   */
  const onProductListShown = useCallback(() => {
    sendListViewedEvent(productListId, products, lang);
  }, [productListId, products, lang]);

  return (
    <div className="Main__recently">
      <h2 className="h1 m-32 m-64-t">
        <Trans>Recently viewed</Trans>
      </h2>
      <ScrollHook once="shown" showOn=".Main__recently" onChanged={onProductListShown} />
      <div className="Wrapper__overload">
        <ProductCarousel flatOnMobile chainedScroll slidesPerPage={slidesPerPage}>
          {products.map((product, i) =>
            product ? (
              <div key={product.id}>
                <ProductCard
                  product={product}
                  link={getProductLink(lang, product)}
                  position={i}
                  listId={productListId}
                />
              </div>
            ) : null,
          )}
        </ProductCarousel>
      </div>
    </div>
  );
}

function PredictedOffer({ lang, products = [] }) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // const isXDesktop = !isSSR && useMediaQuery("(min-width: 1300px)");
  const cityName = products[0].city.name;
  const { isDesktop } = useMediaQueries();
  const slidesPerPage = isDesktop ? 4 : 3;
  const productListId = "predicted_offer";

  /**
   * Product list became visible in viewport
   */
  const onProductListShown = () => {
    sendListViewedEvent(productListId, products, lang);
  };

  return (
    <div className="Main__predictedOffer">
      <h2 className="h1 m-32 m-64-t">
        <Trans>Going to {inclineIfNeeded(cityName, "to", lang)}? Look at our recommendations</Trans>
      </h2>
      <ScrollHook once="shown" showOn=".Main__predictedOffer" onChanged={onProductListShown} />
      <div className="Wrapper__overload">
        <ProductCarousel flatOnMobile chainedScroll slidesPerPage={slidesPerPage}>
          {products.map((product, i) => (
            <div key={product.id}>
              <ProductCard
                product={product}
                link={getProductLink(lang, product)}
                position={i}
                listId={productListId}
              />
            </div>
          ))}
        </ProductCarousel>
      </div>
    </div>
  );
}

const Cities = withI18n()(function PureCities({ lang, nearbyCities = [], topCities = [], i18n }) {
  const tabNames = [];
  if (topCities.length) tabNames.push(i18n._(t`Top cities`));
  if (nearbyCities.length) tabNames.push(i18n._(t`Nearby cities`));

  return (
    <div className="Main__cities">
      <ProductTabs tabNames={tabNames}>
        {[topCities.slice(0, 8), nearbyCities.slice(0, 8)].map((data, i) => (
          <div key={i ? "nearby" : "top"} className="Main__citiesGroup">
            <CitiesGroup external cities={data} />
          </div>
        ))}
      </ProductTabs>
      <Link
        arrow
        external
        to={reverseUrl("destinations", { lang })}
        className="Main__more"
        theme="heavy"
      >
        <Trans>Explore all destinations</Trans>
      </Link>
    </div>
  );
});

function Popular({ lang, products = [], location }) {
  const locationName = inclineIfNeeded(get(location, "name"), "in", lang);
  const productListId =
    location && location.name
      ? `${location.slug ? location.slug : "popular_in_country"}_${location.id}`
      : "popular_worldwide";

  /**
   * Product list became visible in viewport
   */
  const onProductListShown = () => {
    sendListViewedEvent(productListId, products, lang);
  };

  return (
    <div className="Main__popular">
      <h2 className="h1 m-32 m-64-t">
        {location && location.name ? (
          <Trans>Popular in {locationName}</Trans>
        ) : (
          <Trans>Popular worldwide</Trans>
        )}
      </h2>
      <ScrollHook once="shown" showOn=".Main__popular" onChanged={onProductListShown} />
      <ProductsGrid withFavorite className="m-24" products={products} listId={productListId} />
    </div>
  );
}

const Destinations = withI18n()(function Destinations({
  lang,
  destinations = [],
  categories = [],
  i18n,
}) {
  const tabNames = [];
  if (destinations.length) tabNames.push(i18n._(t`Top destinations`));
  if (categories.length) tabNames.push(i18n._(t`Top categories`));

  return (
    <div className="Main__destinations">
      <ProductTabs tabNames={tabNames}>
        {[destinations, categories].map((data, i) => (
          <div key={i ? "destinations" : "categories"}>
            {data.map(destination => (
              <Button
                key={destination.id}
                external
                to={reverseUrl("city", {
                  lang,
                  citySlug: destination.slug,
                  cityId: destination.id,
                })}
                theme="tag"
              >
                {destination.name}
              </Button>
            ))}
          </div>
        ))}
      </ProductTabs>
    </div>
  );
});

function Main({
  lang,
  overview = {},
  products = {},
  viewed = [],
  predicted = [],
  destinations = [],
  categories = [],
  currentLocation,
}) {
  const { isMobile } = useMediaQueries();
  const [headerSearch, setHeaderSearch] = useState(false);
  usePageViewTracking("main");

  return (
    <Root
      stickyHeader
      hideAppMarkets
      isMainPage
      searchInHeader={headerSearch}
      destinations={destinations}
      title={t`WeGoTrip — Audio Tours & Tourist Audio Guides around the world`}
      description={t`WeGoTrip — Find high-quality audio guides with tickets to attractions from experts for self-travel around the world.`}
    >
      <AlternateAuto route="main" />
      <CanonicalAuto route="main" />
      <ScrollHook showOn=".TopBar__search .Search__row" edge="bottom" onChanged={setHeaderSearch} />
      <TopBar />
      <JsonLd
        item={{
          "@context": "https://schema.org",
          "@type": "Organization",
          name: "WeGoTrip",
          url: "https://wegotrip.com",
          logo: "https://wegotrip.com/logo.png",
          sameAs: [
            "https://www.facebook.com/wegotrip",
            "https://twitter.com/wegotrip",
            "https://www.instagram.com/wegotrip",
          ],
        }}
      />
      <div className="Wrapper">
        <ErrorBoundary>
          <LazyHydrate whenVisible>
            {viewed.length ? <Viewed lang={lang} products={viewed} /> : null}
          </LazyHydrate>
        </ErrorBoundary>
        <ErrorBoundary>
          <LazyHydrate whenVisible>
            {predicted.length ? <PredictedOffer lang={lang} products={predicted} /> : null}
          </LazyHydrate>
        </ErrorBoundary>
        <LazyHydrate whenVisible>
          <Benefits className="m-24" />
        </LazyHydrate>
        <ErrorBoundary>
          <LazyHydrate whenVisible>
            {(overview.topCities && overview.topCities.length) ||
            (overview.nearbyCities && overview.nearbyCities.length) ? (
              <Cities lang={lang} {...overview} />
            ) : null}
          </LazyHydrate>
        </ErrorBoundary>
        <ErrorBoundary>
          <LazyHydrate whenVisible>
            {products.products?.length ? (
              <Popular products={products.products} location={currentLocation} lang={lang} />
            ) : null}
          </LazyHydrate>
        </ErrorBoundary>
        <ErrorBoundary>
          <LazyHydrate whenVisible>
            {overview.topAttractions && overview.topAttractions.length ? (
              <Attractions withTickets attractions={overview.topAttractions} isMobile={isMobile} />
            ) : null}
          </LazyHydrate>
        </ErrorBoundary>
      </div>
      <LazyHydrate whenVisible>
        <div className="Wrapper">
          <ErrorBoundary>
            {destinations.length || categories.length ? (
              <Destinations lang={lang} destinations={destinations} categories={categories} />
            ) : null}
          </ErrorBoundary>
        </div>
        <LazyHydrate whenVisible>
          <Banner />
        </LazyHydrate>
      </LazyHydrate>
    </Root>
  );
}

Main.getInitialProps = withRedirectToKnownLang(async ({ req, match, store }) => {
  const userAgent = getUserAgent(req);
  const mobile = isMobileUserAgent(userAgent);
  const lang = getCurrentLanguage(match.params.lang);

  const cacheKey = `main_page_${lang}_${mobile ? "mobile" : "desktop"}`;

  const cachedData = await store.getState().cache?.get(cacheKey);
  if (cachedData) {
    return cachedData;
  }

  const [currency, overview] = await Promise.all([
    fetchDefaultCurrency(req),
    fetchOverview(
      {
        lang,
        expand_overview: "fee,entrance",
        limit: mobile ? 6 : 12,
      },
      req,
    ),
  ]);

  store.dispatch({
    type: types.FETCH_USER,
    cookies: get(req, "headers.cookie"),
  });

  store.dispatch({ type: types.SET_DEFAULT_CURRENCY, defaultCurrency: currency });

  const {
    currentCity: { id: cityId, itemsCount: cityCount } = {},
    currentCountry: { id: countryId, itemsCount: countryCount } = {},
  } = removeNullKeys(overview);

  const productsParams = {
    lang,
    limit: mobile ? 6 : 12,
    expand: "images",
    preorder: true,
    sort: "popular",
  };

  if (cityId && cityCount) {
    productsParams.cityId = cityId;
  } else if (countryId && countryCount) {
    productsParams.countryId = countryId;
  }

  await store.dispatch({
    type: types.FETCH_PRODUCTS,
    ...productsParams,
  });

  const destinations = overview.allDestinations || [];
  const categories = destinations.filter(city => city.category);

  const productIds = getViewed(get(req, "headers.cookie"));
  let viewedTours = [];
  let predictedTours = {};

  if (productIds.length) {
    try {
      const viewedResponse = await Api.get(
        `/api/v2/products/?${toQueryString({
          lang,
          currency,
          product_id: productIds.slice(0, 4).join(","),
          expand: "images",
          preorder: true,
        })}`,
        { lang },
      );

      viewedTours = viewedResponse.data.results;

      const predictedResponse = await (viewedTours[0]?.city?.id
        ? Api.get(
            `/api/v2/products/popular/?${toQueryString({
              lang,
              currency,
              city: viewedTours[0].city.id,
              expand: "images",
              preorder: true,
              limit: mobile ? 4 : 6,
            })}`,
            { lang },
          )
        : Promise.resolve({ data: { results: [] } }));

      predictedTours = predictedResponse.data;
    } catch (error) {
      console.error("Error on loading info about viewed tours", error);
    }
  }

  const result = {
    overview,
    currentLocation:
      (cityCount && overview.currentCity) || (countryCount && overview.currentCountry),
    lang,
    mobile,
    viewed: viewedTours.filter(product => product.locale === lang),
    predicted: get(predictedTours, "results", []).filter(tour => tour.id !== viewedTours[0]?.id),
    destinations,
    categories,
  };

  await store.getState().cache?.set(cacheKey, result, 300);

  return result;
});

const mapStateToProps = ({ products }) => ({
  products,
});

export default connect(mapStateToProps)(Main);
